import React from 'react';
import styled from 'styled-components';

import SEO from 'components/shared/SEO';
import Section from 'components/shared/Section';

import { formatGMTDate } from 'utils/formatDate';
import { graphql } from 'gatsby';

const NewWrapper = styled.div`
  max-width: 700px;
  min-height: calc(100vh - 160px);

  margin: 0px auto;
  padding-top: 80px;

  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const NewHeading = styled.div`
  padding: 16px 0px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;

  ${({ theme }) => theme.mq.s} {
    font-size: 4rem;
    line-height: 5rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 5rem;
    line-height: 6rem;
  }
`;

const Date = styled.div`
  margin: 0px auto;

  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;

  color: rgb(136, 136, 136);
`;

const NewContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    margin-top: 10px;

    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 300;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 400;

    margin-top: 0.5rem;
  }

  ul,
  ol {
    padding-left: 1rem;
  }

  li {
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const NewSingle = ({ data, pageContext }) => {
  console.log(data, pageContext);

  const DATA = data.wpPost;

  return (
    <Section>
      <SEO title="Strona główna" />

      <NewWrapper>
        <NewHeading>
          <Title
            dangerouslySetInnerHTML={{
              __html: DATA.title,
            }}
          />

          <Date
            dangerouslySetInnerHTML={{
              __html: formatGMTDate(DATA.dateGmt),
            }}
          />
        </NewHeading>

        <NewContent
          dangerouslySetInnerHTML={{
            __html: DATA.blog.blogText,
          }}
        />
      </NewWrapper>
    </Section>
  );
};

export const query = graphql`
  query querySingleNew($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      dateGmt
      blog {
        blogText
      }
    }
  }
`;

export default NewSingle;
